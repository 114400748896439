import React, { Component } from 'react';
import PageHeader from '../components/PageHeader';
import SEO from '../components/SEO';
import Layout from '../layout';

class Page extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Legal" keywords={['']} pathname={this.props.location.pathname} />

        <PageHeader>
          <h1>Polypane Terms of Service</h1>
        </PageHeader>
        <div className="page childPadding">
          <p>
            <em>Version 1 &mdash; April 5, 2019</em>
          </p>

          <p>
            This agreement (the “Agreement”) is entered into on the purchase date between Firstversionist B.V (“us”,
            “we”, “our” or “Firstversionist”), The Netherlands: KVK 74263404 , VAT NL859829765B01, and the customer
            (“you”, “your” or “Customer”) (each a “Party” and collectively the “Parties”).
          </p>

          <p>
            Please read this Terms of Service agreement before you purchase a license for the Application and
            subsequently download and use the Application. By purchasing a license and/or downloading and using the
            Application, Customer agrees, without reservation, to be bound by the terms of this Agreement.
          </p>

          <h2>1. Definitions</h2>

          <p>
            The following capitalized terms will have the meaning as described in this section, or in the section where
            they are initially used.
          </p>

          <p>
            1.a “Application” means any software tool made available by Firstversionist to Customer though a license
            purchase from one of our websites or through any other agreement between Customer and Firstversionist, as
            well as any hosted service associated with that software tool (such as online sharing).
          </p>
          <p>1.b “Authorized Devices” means desktop or other devices that the Application can be used on.</p>
          <p>
            1.c “Content” means any content, graphics, designs, documents or websites created using the Application by
            the Customer and/or its Users.
          </p>
          <p>
            1.d “Documentation” means the materials made available by Firstversionist to the Customer and/or the User,
            in any form, describing the use and operation of the Application.
          </p>
          <p>
            1.e “User” means an employee, contractor or individual associated with a Customer who has been given access
            to the Application by the Customer.
          </p>

          <h2>2. License and Use Rights</h2>

          <h3>2.a The Application. </h3>
          <p>
            Firstversionist hereby grants Customer a non-exclusive, non-transferable license during the Term (as defined
            in section 11.a) to (a) download, install and use the Application on the number and type of Authorized
            Devices solely for Customers internal business purposes in accordance with the Documentation and (b) use
            hosted systems provided to the Customer as part of the Customers Application subscription. The Application
            is delivered electronically by provision of download links and license keys.
          </p>

          <h3>2.b Provisioning of the application. </h3>
          <p>
            Firstversionist will provide to Customer the necessary account details, security protocols, policies,
            network links or connections (together, the “Access Protocols”) to allow Customer and its Users to access
            the Application as described herein. Customers shall permit Users to access and use the features of the
            Application through the Access Protocols. Customer may select one User to act as administrators and control,
            manage and use the Application on Customers behalf. Customer shall be responsible for all acts and omissions
            of its Users.
          </p>

          <h3>2.c Software restrictions.</h3>
          <p>
            Customer will not, and will not authorize any User to: (a) copy or duplicate the Application; (b) decompile,
            disassemble, reverse engineer or otherwise attempt to obtain or perceive the source code from which any
            software component of the Application is compiled or interpreted; (c) modify the Application or the
            Documentation, or create any derivative work from any of the foregoing, except with the prior written
            consent of Firstversionist; (d) assign, sublicense, sell, resell, lease, rent or otherwise transfer or
            convey, or pledge as security or otherwise encumber, Customers rights under section 2.a and 2.b; (e) build a
            similar or competitive product or service; (f) damage, interfere with or disrupt the integrity, performance
            or use of the Application; or (g) use the Application for any purposes prohibited by law. Customer will not
            conceal, remove, obscure or alter (1) any proprietary notice or legend regarding Firstversionist’s
            proprietary rights in the Application or (2) trademarks or logos displayed as part of the Application.
            Customer will ensure that its use of the Application complies with all applicable laws, statutes,
            regulations and rules.
          </p>

          <h3>2.d Acceptable use.</h3>
          <p>
            Customer and its Users shall not (a) use the Application for uploading or sharing any content than the
            Content; (b) use the Application for any unlawful purposes or the promotion of illegal activities; (c) use
            the Application in a way that could be detrimental to the reputation of Firstversionist; (d) upload or share
            any Content that infringes intellectual property rights of third parties, would violate laws applicable to
            the Content or would cause Firstversionist to violate applicable law; (e) imply or suggest that the Content
            is created or endorsed by Firstversionist or our licensors; (f) probe, scan or test the vulnerability of any
            system or network or breach to circumvent any security or authentication measures, including measures that
            prevent or restrict use or copying of the Content or enforce limitations on the use of the Application or
            material accessible via the Application; (g) use the Application for any purpose other than those in
            accordance with the Agreement including, but not limited to, (1) sale of access to the Application, (2) sale
            of advertising or promotion placed on or within the Content or the Application, or (3) promotions on any
            webpage containing the Content delivered via the Application; (h) impersonate or misrepresent
            Firstversionist or any third party when making Content available; or (I) interfere with or disrupt
            Firstversionists services, servers or network in any way.
          </p>

          <h3>2.e Publicity. </h3>
          <p>
            During the term or at any point thereafter, Firstversionist may publicly refer to Customer orally and in
            writing, including on Firstversionist’s websites and sales presentations, as a customer of Firstversionist,
            and may use Customer’s logo and company name for such purposes.
          </p>

          <h3>2.f Communication.</h3>
          <p>
            Firstversionist may sent communications such as updates, newsletters, marketing or promotional materials or
            other material to Customer. Customer can opt out of receiving any, or all, communications by following the
            unsubscribe links provided in such communications.
          </p>

          <h3>2.e Support. </h3>
          <p>
            Firstversionist shall provide Customers with the following standard support, unless Customer purchases
            additional support services, such as, but not limited to, extended or enterprise support: (a) e-mail support
            to Users Monday through Friday from 9:00 to 17:00 CEST, Dutch holidays excluded, for problem resolution
            assistance; (b) commercially reasonable efforts to correct errors in the Application reported to
            Firstversionist in writing; and (c) at the sole discretion of Firstversionist, the provision of updates and
            other improvements that Firstversionist makes generally available to subscribers of the Application.
          </p>

          <h2>3. Ownership Rights</h2>

          <h3>3.a No IP Rights transfer. </h3>
          <p>
            Other than the licenses described herein, no intellectual property rights are transferred by either party to
            the other pursuant to this Agreement.
          </p>

          <h3>3.b What Firstversionist owns. </h3>
          <p>
            As between the Parties, Firstversionist shall own all rights, including, but not limited to, all copyright
            rights, in the Application, including any content or trademarks contained therein or thereon. Except for the
            rights expressly granted herein, Customer acquires no rights, title or interest in the Application.
          </p>

          <h3>3.c What Customer owns.</h3>
          <p>
            As between the Parties, Customer shall own all rights in and Firstversionist disclaims any rights in
            Customers Content.
          </p>

          <h2>4. Customer Content</h2>

          <p>
            4.a Customer and its Users may upload and share Content via the Application. After the Content is
            successfully uploaded, a link is made available allowing users to share the Content by sharing the link.
          </p>

          <p>
            4.b Firstversionist is not responsible or liable for the Content and the consequences of uploading the
            Content and for sharing the Content by sharing the link. Firstversionist does not endorse the Content or
            anything expressed in the Content.
          </p>

          <p>
            4.c Although Firstversionist has no obligation to monitor Content, we may remove Content and/or prohibit the
            use of the Application we believe may be (or alleged to be) in violation of the foregoing.
          </p>

          <p>
            4.d License to Display Content. Customer grants: (a) Firstversionist a worldwide, non-exclusive, royalty
            free, transferable license with a right to sub-license to use, reproduce, distribute, display and perform
            the Content to the extend required for the provision of the Application; and (b) individuals with whom
            Content is shared via the Application a personal, non-exclusive, royalty free license to access the Content
            and to use the Content in accordance with the terms of this agreement. The foregoing license terminates
            automatically when Content is removed from the Application.
          </p>

          <h2>5. Security</h2>

          <p>
            Firstversionist will use procedural, technical, and administrative safeguards designed to ensure the
            confidentiality, security, integrity, availability, and privacy of Content and other Customer Confidential
            Information stored in the Application. Customer is responsible for reviewing the information made available
            by Firstversionist relating to data security and making an independent determination as to whether the
            Application meets Customer’s requirements and legal obligations. Customer acknowledges that
            Firstversionist’s security measures are subject to technical progress and development and that
            Firstversionist may update or modify such measures from time to time provided that such updates and
            modifications do not result in a material decrease of the overall security of the Application during a
            subscription term.
          </p>

          <h2>6. Confidentiality</h2>

          <h3>6.a Confidential information. </h3>
          <p>
            Subject to the limitations set forth in Section 11.b, all information disclosed by one party to the other
            party during the term of this Agreement that is identified in writing at the time of disclosure as
            confidential or that reasonably should be understood to be confidential given the nature of the information
            and/or the circumstance of the disclosure, in any form of communication, shall be deemed to be “Confidential
            information”. The existence and terms of this Agreement are Confidential information of both parties.{' '}
          </p>

          <h3>6.b Exceptions. </h3>
          <p>
            Information will not be considered Confidential Information if the receiving party can establish by
            documentary evidence that the information is or was: (a) publicly available or in the public domain at the
            time of disclosure through no fault of the recipient; (b) rightfully communicated to the recipient by
            persons not bound by confidentiality obligations with respect hereto; (c) already in the recipient’s
            possession free of any confidentiality obligations with respect hereto at the time of disclosure; (d)
            independently developed by the recipient without any use of or by persons who have access to the other
            Party’s Confidential Information; or (e) is approved in writing for release or disclosure by the disclosing
            Party without restriction.
          </p>

          <h3>6.c Nondisclosure.</h3>
          <p>
            During the course of this Term, either Party may disclose Confidential Information to the other Party. Each
            Party agrees that all items of Confidential Information are proprietary to the disclosing Party or such
            third party, as applicable, and will remain the sole property of the disclosing Party or such third party.
            Each party further agrees: (a) to use Confidential Information disclosed by the other Party only for the
            purposes described herein; and (b) that such Party will not reproduce Confidential Information disclosed by
            the other Party, except as necessary to carry out its obligations and exercise its rights under this
            Agreement, and will hold in confidence and protect such Confidential Information from dissemination to, and
            use by, any third party.
          </p>

          <h3>6.d Legally Required Disclosure.</h3>
          <p>
            Notwithstanding the foregoing, each Party may disclose Confidential Information to the limited extend
            required to comply with the order of a court or other governmental body, or as otherwise necessary to comply
            with applicable law, provided that the Party making the disclosure pursuant to the other shall first have
            given notice to the other Party (to the extend permitted) and shall have provided such assistance as may be
            reasonable requested to limit such disclosure.
          </p>

          <h2>7. Fees and Payment</h2>

          <h3>7.a Payment terms. </h3>
          <p>
            Customer will pay Firstversionist, without offset or deduction, all fees due under this Agreement. All
            payments will be in U.S. Dollars or EU Euro’s. Unless otherwise specified, all fees shall be due 30 days
            from the date of Firstversionist’s invoice and all fees are non-cancelable and non-refundable. All amounts
            will exclude all applicable sales, use and other taxes.
          </p>

          <h3>7.b Merchant of Record. </h3>
          <p>
            Our order process is conducted by our online reseller Paddle.com. Paddle.com is the Merchant of Record for
            all our orders. Paddle provides all customer service inquiries and handles returns.
          </p>

          <h3>7.c Fee changes.</h3>
          <p>
            Firstversionist may, at it’s sole discretion and at any time, modify the fees due under this Agreement. Any
            fee change will become effective at the end of the then-current billing cycle. Customer will be provided
            with a reasonable prior notice of any changes and will be given the opportunity to choose different terms
            when available or terminate the Agreement in accordance with Section 11.
          </p>

          <h3>7.d Direct Sales.</h3>
          <p>
            Firstversionist and Customer may agree to different payment terms in a specific agreement, including but not
            limited to, direct invoicing. Any specific agreement always supersedes the general terms of service. All
            fees for direct invoices shall be due 14 days from the date of Firstversionist’s invoice and all fees are
            non-cancelable and non-refundable. Direct invoices may be paid by Bank transfer or, when available, by iDeal
            or credit card via Mollie.com.
          </p>

          <h2>8. Warranties and Disclaimers</h2>

          <h3>8.a Authority.</h3>
          <p>
            Each of Firstversionist and Customer represents and warrants that: (a) it has the full right, power and
            authority to enter into and fully perform this Agreement; (b) the person signing this Agreement on its
            behalf is a duly authorized representative of such party who has been authorized to execute this Agreement;
            (c) Its entry herein does not violate any other agreement by which it is bound and (d) it is a legal entity
            in good standing in the jurisdiction of its formation.
          </p>

          <h3>8.b Rights to Content. </h3>
          <p>
            Customer warrants that it has and will continue to have during the Term all necessary licenses, rights,
            consents and permissions that are required to enable Firstversionist to use the Content as required to
            provide the Application.
          </p>

          <h3>8.c Disclaimer.</h3>
          <p>
            To the maximum extend permitted by applicable law, the Application and related services are provided “as is”
            and Firstversionist disclaims any and all other representations and warranties, express or implied,
            including, but not limited to, any implied warranties of merchantability, fitness for a particular purpose,
            non-infringement, system integration and/or data accuracy. Firstversionist does not warrant that the
            Application or any other services provided by Firstversionist will meet Customers’s requirements or operate
            uninterrupted or error-free. The Application may be subject to limitations, delays and other problems
            inherent in the use of the internet and electronic communications. Firstversionist is not responsible for
            any delays, delivery failures or other damages resulting from such problems. Neither Firstversionist or its
            third-party providers shall have any liability for Customer’s use of Content or other materials or
            information made available through or developed using the Application. Firstversionist shall have no
            responsibility for determining that the Customers proposed use of the Application complies with applicable
            laws in Customer’s jurisdiction.
          </p>

          <h3>8.d Changes to Service</h3>
          <p>
            Firstversionists reserves the right to withdraw or amend the Application and any Documentation , service or
            material we provide, in our sole discretion without notice. Firstversionist may restrict access to some
            parts or all of the Service to Users.
          </p>

          <h2>9. Liability Limitation</h2>

          <h3>9.a</h3>
          <p>
            Except for a breach by Customer of section 2.c: (a) in no event will either party be liable to the other
            party for any incidental, indirect, special, consequential or punitive damages, regardless of the nature of
            the claim, including, without limitation, lost profits, costs of delay, any failure of delivery, business
            interruption, costs of lost or damaged data, or liabilities to third parties arising from any source, even
            if such party has been advised of the possibility of damages; and (b) the cumulative liability of
            Firstversionist to Customer for all claims arising from this agreement, including, without limitation, any
            cause of action sounding on contract, tort, or strict liability, will not exceed the fees paid to
            Firstversionist by Customer during the twelve month period prior to the event giving rise to any claim. The
            foregoing shall not limit Customer’s payment obligations. These limitations upon damages and claims are
            intended to apply without regard to whether other provisions of this agreement have been breached or have
            proven ineffective.
          </p>

          <h3>9.b. Basis.</h3>
          <p>
            Customer acknowledges and understands that the disclaimers, exclusions and limitations of liability in this
            Agreement form an essential basis of the Parties’ agreement and that absent of such disclaimers, exclusions
            and limitations of liability, the terms and conditions of this Agreement would be substantially different.
          </p>

          <h2>10. Indemnification</h2>

          <h3>10.a By Firstversionist. </h3>
          <p>
            Firstversionist will defend at its expense any suit brought against Customer by a third party, and will pay
            any settlement Firstversionist makes or approves, or any damages finally awarded in such suit, insofar as
            such suit is based on a claim by any third party alleging that the Application misappropriates any trade
            secret recognized under the Uniform Trade Secrets Act or infringes any copyright or trademark issued as of
            the Effective Date. Notwithstanding the foregoing, Firstversionist shall have no obligation under this
            section or otherwise with respect to any infringement claim based upon (a) any use of the Application not in
            accordance with this Agreement; (b) any use of the Application in combination with other products,
            equipment, software or data not supplied by Firstversionist; (c) any modification of the Application by any
            person other than Firstversionist or its authorized agents; or (d) Content. If, due to a claim of
            infringement, the Application is held by a court of competent jurisdiction to be or is believed by
            Firstversionist to be infringing, Firstversionist may, at its option and expense: (i) replace or modify the
            Application to be non-infringing provided that such modification or replacement contains substantially
            similar features and functionality, (ii) obtain for Customer a license to continue using the Application, or
            (iii) if neither of the foregoing is commercially practicable, terminate this Agreement and provide Customer
            a refund of any prepaid, unused fees for the Application. This section states Firstversionist’s entire
            obligation and liability with respect to any claim of infringement.
          </p>

          <h3>10.b By Customer.</h3>
          <p>
            Customer will defend, indemnify, and hold Firstversionist harmless from and against any losses, liabilities,
            costs (including reasonable attorneys’ fees) or damages to the extent resulting from Customer’s breach or
            alleged breach of its obligations, representations and warranties under this Agreement, or claims that
            Content created by or on behalf of Customer infringe the intellectual property rights of any third party.
          </p>

          <h3>10.c Process.</h3>
          <p>
            The foregoing indemnification obligations are subject to the following: (a) the indemnified Party will
            promptly notify the indemnifying Party of any claim for which indemnification is sought; (b) the
            indemnifying Party will have control of the defense or settlement; and (c) the indemnified Party will
            reasonably cooperate with the defense, at the indemnifying Party's expense.
          </p>

          <h2>11. Term and Termination</h2>

          <h3>11.a Term.</h3>
          <p>
            The term of this Agreement will commence on the Effective Date and will continue as long as payment
            continues. (“Term”).
          </p>

          <h3>11.b Termination.</h3>
          <p>
            Either Party may, at its option, terminate this Agreement in the event the other Party: (a) materially
            breaches this Agreement and fails to cure such breach (or provide an acceptable plan for curing such breach)
            within 30 days after receipt by the breaching Party of written notice specifying the breach; (b) ceases
            operation without a successor; or (c) seeks protection under any bankruptcy, receivership, trust deed,
            creditors arrangement, composition or comparable proceeding, or if any such proceeding is instituted against
            such party (and not dismissed within 60 days). Termination is not an exclusive remedy and the exercise by
            either party of any remedy under this Agreement will be without prejudice to any other remedies it may have
            under this Agreement, by law, or otherwise.
          </p>

          <h3>11.c Suspension.</h3>
          <p>
            If Customer fails to pay any undisputed amounts hereunder or, as necessary to protect the security of the
            Application, Firstversionist will have the right, in addition to any of its other rights or remedies, to
            immediately suspend access to the Application to Customer and/or any of its Users, without liability, until
            such amounts are paid in full or such threat no longer exists, as applicable.{' '}
          </p>

          <h3>11.d Effects.</h3>
          <p>
            Upon termination or expiration of this Agreement, Customer will immediately discontinue use of the
            Application. The Sections of this Agreement which by their nature are intended to survive termination,
            including but not limited to Sections 3, 7, 8, 10, 11 and 12, will remain in effect after such termination.
            Within 10 (ten) business days following the termination date, Customer shall, at Firstversionist’s option,
            return to Firstversionist or destroy (and certify to Firstversionist in writing as to such destruction) all
            copies of the Application and Documentation and any other materials embodying or reflecting the Application
            and any other Firstversionist Confidential Information. On termination or expiration of this Agreement other
            than termination by Customer for Firstversionist’s breach, Customer will immediately pay Firstversionist, as
            liquidated damages, the remaining balance (if any) for the remainder of the subscription Term.
          </p>

          <h2>12. Miscellaneous</h2>

          <h3>12.a Integration. </h3>
          <p>
            This Agreement is the entire agreement between the Parties related to the subject matter hereof and
            supersedes all prior and contemporaneous agreements, understandings and discussions. Customer and
            Firstversionist waive their rights to rescind or annul these Terms. Articles 3:44, 6:228 and 6:265 of the
            Dutch Civil Code are excluded. This Agreement shall supersede the terms of any purchase order or other
            business form. If accepted by Firstversionist, Customer’s purchase order shall be binding only as to the
            following terms: the Application ordered and the appropriately calculated fees due. Other terms shall be
            void.
          </p>

          <h3>12.b Construction; interpretation.</h3>
          <p>
            If a court of competent jurisdiction finds any provision of this Agreement to be unenforceable, that
            provision of the Agreement will be enforced to the maximum extent permissible so as to effect the intent of
            the Parties, and the remainder of this Agreement will continue in full force. No waiver hereunder will be
            valid or binding unless set forth in writing and duly executed by the Party against whom enforcement of such
            waiver is sought.
          </p>

          <h3>12.c No Agency Relationship; No Third Party Beneficiaries.</h3>
          <p>
            Nothing in this agreement will be construed to create any agency, partnership, or joint venture between the
            Parties, and neither Party will represent itself as an agent or legal representative of the other Party. To
            the extend that any third party stipulation is contained in this Agreement, article 6:254 of the Dutch Civil
            Code is excluded. The Parties acknowledge that the covenants set forth in this Agreement are intended solely
            for the benefit of the Parties, their successors and permitted assigns.
          </p>

          <h3>12.d Amendment.</h3>
          <p>The Agreement can be amended only by a writing signed by both Parties.</p>

          <h3>12.e Governing Law; Arbitration.</h3>
          <p>
            This Agreement will be and interpreted in accordance with Dutch law without regard to international law
            regulations or principles of law leading to the application of other laws. The United Nations Convention on
            Contracts for the International Sale of Goods does not apply to this Agreement. Any dispute or claim arising
            out of or in connection with this Agreement or the breach, termination or invalidity thereof shall be
            settled by arbitration in accordance with the International Chamber of Commerce (ICC) rules. Such dispute or
            claim shall be settled by simplified arbitration arranged by ICC in accordance with the rules of arbitration
            procedure adopted by ICC and in force at the time when such proceedings are commenced. Arbitration shall be
            conducted in The Hague, The Netherlands, before one arbitrator appointed in accordance with the ICC Rules.
            All arbitration shall be conducted in English. The award rendered thereon by the arbitrator shall be final
            and binding on the Parties thereto, and judgment thereon shall be confidential and may be entered in any
            court of competent jurisdiction. Nothing in this Section 12.e shall prevent either Party from applying to a
            court of competent jurisdiction for equitable or injunctive relief.{' '}
          </p>

          <h3>12.f Force Majeure.</h3>
          <p>
            Any delay in the performance of any duties or obligations of either Party (except the payment of money owed)
            will not be considered a breach of this Agreement if such delay is caused by any event beyond the control of
            such Party, provided that such Party uses reasonable efforts to notify the other Party of the cause of such
            delay and to resume performance as soon as possible.{' '}
          </p>

          <h3>12.g Notices.</h3>
          <p>
            All notices, requests and other communications to Firstversionist hereunder must be in writing to
            contact@firstversionist.com
          </p>

          <h3>12.h Assignment.</h3>
          <p>
            This Agreement may not be assigned by either Party without the other Party’s prior written consent, whether
            by operation of law or otherwise, except that either Party may assign this Agreement to its successor in the
            event of a merger, acquisition or sale of all or substantially all of the assets of such party. Any other
            purported assignment shall be void.
          </p>

          <h3>12.i Counterparts.</h3>
          <p>
            This Agreement may be executed and delivered by facsimile or electronic signature and in two or more
            counterparts, each of which will be deemed an original, but all of which together will constitute one and
            the same instrument.
          </p>
        </div>
      </Layout>
    );
  }
}

export default Page;
